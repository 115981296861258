import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { Bounce, toast } from 'react-toastify';

// ==============================|| SLICE - PRODUCTS ||============================== //

const initialState = {
  error: null,
  isLoading: false,
  postReq: false,
  customers: [],
  customer: null,
  singleCustomer: {},
  relatedCustomers: [],
  reviews: [],
  addresses: [],
  discountedProductsList: [],
  tierDropdown: [],
  tierList: []
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET PRODUCTS
    getCustomersSuccess(state, action) {
      state.customers = action.payload;
      state.isLoading = false;
    },

    getCustomerDataPending(state) {
      state.isLoading = true;
    },
    // GET SINGLE CUSTOMER

    getSingleCustomerPending(state) {
      state.isLoading = true;
    },

    getSingleCustomerSuccess(state, action) {
      state.isLoading = false;
      state.singleCustomer = action.payload;
    },

    //DISCOUNTED PRODUCTS PENDING
    // discountProductsPending(state) {
    //   state.isLoading = true;
    // },

    //DISCOUNTED PRODUCTS
    discountedProductsSuccess(state, action) {
      state.discountedProductsList = action.payload;
      // state.isLoading = false;
    },
    discountTierListSuccess(state, action) {
      state.tierDropdown = action.payload;
      // state.isLoading = false;
    },

    //GET TIER DATA
    getTierListSuccess(state, action) {
      // console.log(action.payload, 'hhhkh');
      state.isLoading = false;
      state.tierList = action.payload;
    },

    assignDiscountToCustomerPending(state) {
      state.postReq = true;
    },

    assignDiscountToCustomerSuccess(state) {
      // console.log(action.payload, 'lkl');
      // if (!action.payload) {
      //   state.x = true;
      // } else {
      //   state.x = false;
      // }

      state.postReq = false;
    },

    // FILTER PRODUCTS
    filterCustomersSuccess(state, action) {
      state.customers = action.payload;
    },

    // GET PRODUCT
    getCustomerSuccess(state, action) {
      state.customer = action.payload;
    },

    // GET RELATED PRODUCTS
    getRelatedCustomersSuccess(state, action) {
      state.relatedCustomers = action.payload;
    },

    // GET PRODUCT REVIEWS
    getCustomerReviewsSuccess(state, action) {
      state.reviews = action.payload;
    },

    // GET ADDRESSES
    getAddressesSuccess(state, action) {
      state.addresses = action.payload;
    },

    // ADD ADDRESS
    addAddressSuccess(state, action) {
      state.addresses = action.payload;
    },

    // EDIT ADDRESS
    editAddressSuccess(state, action) {
      state.addresses = action.payload;
    }
  },
  pendingState(state) {
    state.isLoading = true;
  },
  successState(state) {
    state.isLoading = false;
  }
});

// Reducer
export default slice.reducer;

// All Customer
export function getCustomers(role) {
  dispatch(slice.actions.getCustomerDataPending());
  return async () => {
    try {
      const response = await axios.get('/users', {
        params: {
          role: role
        },
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
      });
      // const response = await axios.get(`/items`)
      // console.log(response, 'userslist');
      dispatch(slice.actions.getCustomersSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Single Customer
export function getSingleCustomer(ContactId) {
  dispatch(slice.actions.getSingleCustomerPending());
  return async () => {
    try {
      const response = await axios.get('/users', {
        params: {
          role: 'customer',
          contact_id: ContactId
        },
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
      });
      // const response = await axios.get(`/items`)
      // console.log(response, 'userslist');
      dispatch(slice.actions.getSingleCustomerSuccess(response.data.users[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//Approve or decline customers:-
export function ApproveCustomerHandler(ContactId, status) {
  // console.log(ContactId, 'ContactId');
  return async () => {
    // console.log(ContactId, 'okopjui');
    await axios
      .put(
        '/approve',
        {
          approve: !status
        },
        {
          params: {
            role: 'customer',
            contact_id: ContactId
          },
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('serviceToken')
          }
        }
      )
      .then((response) => {
        // console.log(response.data, '9000002');
        dispatch(getCustomers('customer'))
          .then(() => {
            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
              transition: Bounce
            });
          })
          .catch((err) => console.log(err));
      })
      .catch((error) => {
        dispatch(slice.actions.hasError(error));
      });
  };
}

//DISCOUNTED PRODUCTS
export function discountedProducts(role) {
  // dispatch(slice.actions.discountProductsPending());
  return async () => {
    try {
      const response = await axios.get('/items/user', {
        params: {
          role: role
        },
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
      });
      // console.log(response.data.items, 'discountedProducts');
      dispatch(slice.actions.discountedProductsSuccess(response.data.items));
    } catch (error) {
      // console.log(error);
    }
  };
}

//ASSIGN DISCOUNT
export function assignDiscountToCustomer(data) {
  dispatch(slice.actions.assignDiscountToCustomerPending());
  // console.log(data, 'data200');
  return async () => {
    try {
      const response = await axios.post('/customer/add/discount', data, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
      });
      // console.log(response.data.discounted_item, 'assignDiscountToCustomer');
      if (response) {
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          transition: Bounce
        });
        dispatch(slice.actions.assignDiscountToCustomerSuccess());
      }
      dispatch(discountedProducts('customer'));
    } catch (error) {
      // console.log(error);
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce
      });
      dispatch(slice.actions.assignDiscountToCustomerSuccess());
    }
  };
}

// REMOVE DISCOUNT
export function deleteCustomerDiscount(props) {
  return async () => {
    try {
      const response = await axios.delete('/customer/delete/discount', {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') },
        params: {
          customer: props.customerId,
          product: props.productId
        }
      });
      dispatch(discountedProducts('customer'));
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//TIER DROPDOWN
export function discountTierList() {
  return async () => {
    try {
      const response = await axios.get('/tiers', {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
      });
      // console.log(response.data.data, 'discountTierList');
      dispatch(slice.actions.discountTierListSuccess(response.data.data));
    } catch (error) {
      // console.log(error);
    }
  };
}

//GET TIER DATA
export function getTierList() {
  return async () => {
    try {
      const response = await axios.get('/tiers/assign', {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
      });
      // console.log(response.data.data, 'discountTierList');
      dispatch(slice.actions.getTierListSuccess(response.data.data));
    } catch (error) {
      // console.log(error);
    }
  };
}

//POST ASSIGN TIER DATA
export function assignTierToCustomer({ assignTierData, setValueOfCustomer }) {
  return async () => {
    try {
      const response = await axios.post('/tiers/assign', assignTierData, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
      });
      // console.log(response, 'discountTierResponse');
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce
      });
      dispatch(getTierList());
      setValueOfCustomer([]);
    } catch (error) {
      // console.log(error);
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce
      });
    }
  };
}

// UPDATE DISCOUNT OR MARKUP
export function updateDiscountOrMarkup(props) {
  // console.log(props, 'propsForEdit');
  return async () => {
    try {
      const response = await axios.put(
        '/customer/edit/discount',

        Object.keys(props).includes('discount') ? { discount: props.discount } : { markup: props.markup },
        {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') },
          params: {
            product: props.product,
            customer: props.customer
          }
        }
      );

      if (response) {
        dispatch(discountedProducts('customer')).then(() => {
          // console.log(res, 'uggf');
          toast.success(response.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
            transition: Bounce
          });
        });
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterProducts(filter) {
  return async () => {
    try {
      const response = await axios.post('/api/products/filter', { filter });
      dispatch(slice.actions.filterProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProduct(id) {
  return async () => {
    try {
      const response = await axios.post('/api/product/details', { id });
      dispatch(slice.actions.getProductSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedProducts(id) {
  return async () => {
    try {
      const response = await axios.post('/api/product/related', { id });
      dispatch(slice.actions.getRelatedProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductReviews() {
  return async () => {
    try {
      const response = await axios.get('/api/review/list');
      dispatch(slice.actions.getProductReviewsSuccess(response.data.productReviews));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddresses() {
  return async () => {
    try {
      const response = await axios.get('/api/address/list');
      dispatch(slice.actions.getAddressesSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/new', address);
      dispatch(slice.actions.addAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/edit', address);
      dispatch(slice.actions.editAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
