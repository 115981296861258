import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { Bounce, toast } from 'react-toastify';

// ==============================|| SLICE - PRODUCTS ||============================== //

const initialState = {
  error: null,
  isLoading: false,
  orders: [],
  singleOrder: {}
};

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    ///Orders...........
    getOrdersPending(state) {
      state.isLoading = true;
    },
    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
    },

    ///Single Order Detail
    getSingleOrderPending(state) {
      state.isLoading = true;
    },
    getSingleOrderSuccess(state, action) {
      state.isLoading = false;
      state.singleOrder = action.payload;
    },

    ///For close Modal
    clearOrderModalData(state) {
      state.singleOrder = {};
    }
  }
});

// Reducer
export default slice.reducer;

//Get Orders
export function getOrders() {
  dispatch(slice.actions.getOrdersPending());
  return async () => {
    try {
      const response = await axios.get('/orders', {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
      });
      // console.log(response, 'responseOrderProduct');
      dispatch(slice.actions.getOrdersSuccess(response.data.orders));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//GET SINGLE ORDER
export function getSingleOrder(orderId) {
  dispatch(slice.actions.getSingleOrderPending());
  return async () => {
    try {
      const singleOrderDetail = await axios.get('/orders', {
        params: {
          _id: orderId
        },
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
      });
      // console.log(singleOrderDetail, 'singleOrderDetail');
      dispatch(slice.actions.getSingleOrderSuccess(singleOrderDetail.data.orders[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//SEND FUND RECEIVED MAIL
export function getFundReceived(orderId) {
  // console.log(orderId, 'kklkl');
  // dispatch(slice.actions.getSingleOrderPending());
  return async () => {
    try {
      const response = await axios.post(
        '/orders/confirm',
        { orderId: orderId },
        {
          params: {
            subject: 'FundReceived'
          },
          headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
        }
      );
      // console.log(response, 'responseForMail');
      dispatch(getOrders());
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce
      });
      // dispatch(slice.actions.getSingleOrderSuccess(singleOrderDetail.data.orders[0]));
    } catch (error) {
      // console.log(error, 'fundRcvError');
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce
      });
      // dispatch(slice.actions.hasError(error));
    }
  };
}

//SEND FUND RECEIVED MAIL
export function getOrderDelivery({ orderId, downloadUrl }) {
  return async () => {
    try {
      const response = await axios.post(
        '/orders/confirm',
        { orderId: orderId, downloadUrl: downloadUrl },
        {
          params: {
            subject: 'Delivered'
          },
          headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
        }
      );
      // console.log(response, 'responseForMail');
      dispatch(getOrders());
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce
      });
      // dispatch(slice.actions.getSingleOrderSuccess(singleOrderDetail.data.orders[0]));
    } catch (error) {
      // console.log(error, 'fundRcvError');
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce
      });
      // dispatch(slice.actions.hasError(error));
    }
  };
}

export const clearOrderModalData = slice.actions.clearOrderModalData;
