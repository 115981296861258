// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  KyberNetwork,
  Messages2,
  Calendar1,
  Kanban,
  Profile2User,
  // Bill,
  UserSquare,
  ShoppingBag,
  BagTick,
  UserOctagon
} from 'iconsax-react';

// icons
const icons = {
  applications: KyberNetwork,
  chat: Messages2,
  calendar: Calendar1,
  kanban: Kanban,
  customer: Profile2User,
  // invoice: Bill,
  supplier: UserOctagon,
  profile: UserSquare,
  ecommerce: ShoppingBag,
  orders: BagTick
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications = {
  id: 'group-applications',
  title: <FormattedMessage id="applications" />,
  icon: icons.applications,
  type: 'group',
  children: [
    // {
    //   id: 'chat',
    //   title: <FormattedMessage id="chat" />,
    //   type: 'item',
    //   url: '/apps/chat',
    //   icon: icons.chat,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'calendar',
    //   title: <FormattedMessage id="calendar" />,
    //   type: 'item',
    //   url: '/apps/calendar',
    //   icon: icons.calendar
    // },
    // {
    //   id: 'kanban',
    //   title: <FormattedMessage id="kanban" />,
    //   type: 'item',
    //   icon: icons.kanban,
    //   url: '/apps/kanban/board'
    // },
    {
      id: 'customer',
      title: <FormattedMessage id="customer" />,
      type: 'collapse',
      icon: icons.customer,
      children: [
        {
          id: 'customer-list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/apps/customer/customer-list'
        },
        // {
        //   id: 'customer-card',
        //   title: <FormattedMessage id="cards" />,
        //   type: 'item',
        //   url: '/apps/customer/customer-card'
        // }
        {
          id: 'assign-discount-customer',
          title: <FormattedMessage id="Assign Discount" />,
          type: 'item',
          url: '/apps/customer/assign-discount-customer'
        },
        {
          id: 'assign-tier-customer',
          title: <FormattedMessage id="Assign Tier" />,
          type: 'item',
          url: '/apps/customer/assign-tier-customer'
        }
      ]
    },
    {
      id: 'supplier',
      title: <FormattedMessage id="Supplier" />,
      type: 'collapse',
      icon: icons.supplier,
      children: [
        {
          id: 'supplier-list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/apps/supplier/supplier-list'
        },
        {
          id: 'assign-discount-supplier',
          title: <FormattedMessage id="Assign Discount" />,
          type: 'item',
          url: '/apps/supplier/assign-discount-supplier'
        }
        // {
        //   id: 'datatable-supplier',
        //   title: <FormattedMessage id="Datatable" />,
        //   type: 'item',
        //   url: '/apps/supplier/datatable-supplier'
        // }

        // {
        //   id: 'customer-card',
        //   title: <FormattedMessage id="cards" />,
        //   type: 'item',
        //   url: '/apps/customer/customer-card'
        // }
      ]
    },
    // {
    //   // id: 'invoice',
    //   // title: <FormattedMessage id="invoice" />,
    //   // url: '/apps/invoice/dashboard',
    //   type: 'collapse',
    //   // icon: icons.invoice,
    //   // breadcrumbs: true,
    //   children: [
    //     // {
    //     //   id: 'create',
    //     //   title: <FormattedMessage id="create" />,
    //     //   type: 'item',
    //     //   url: '/apps/invoice/create'
    //     // },
    //     // {
    //     //   id: 'details',
    //     //   title: <FormattedMessage id="details" />,
    //     //   type: 'item',
    //     //   url: '/apps/invoice/details/1'
    //     // },
    //     // {
    //     //   id: 'list',
    //     //   title: <FormattedMessage id="list" />,
    //     //   type: 'item',
    //     //   url: '/apps/invoice/list'
    //     // },
    //     // {
    //     //   id: 'edit',
    //     //   title: <FormattedMessage id="edit" />,
    //     //   type: 'item',
    //     //   url: '/apps/invoice/edit/1'
    //     // }
    //   ]
    // },
    // {
    //   id: 'profile',
    //   title: <FormattedMessage id="profile" />,
    //   type: 'collapse',
    //   icon: icons.profile,
    //   children: [
    //     {
    //       id: 'user-profile',
    //       title: <FormattedMessage id="user-profile" />,
    //       type: 'item',
    //       url: '/apps/profiles/user/personal',
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'account-profile',
    //       title: <FormattedMessage id="account-profile" />,
    //       type: 'item',
    //       url: '/apps/profiles/account/basic'
    //     }
    //   ]
    // },

    {
      id: 'e-commerce',
      title: <FormattedMessage id="e-commerce" />,
      type: 'collapse',
      icon: icons.ecommerce,
      children: [
        // {
        //   id: 'products',
        //   title: <FormattedMessage id="products" />,
        //   type: 'item',
        //   url: '/apps/e-commerce/products'
        // },
        // {
        //   id: 'product-details',
        //   title: <FormattedMessage id="product-details" />,
        //   type: 'item',
        //   url: '/apps/e-commerce/product-details/1',
        //   breadcrumbs: false
        // },
        // {
        //   id: 'product-details',
        //   title: <FormattedMessage id="Product Details" />,
        //   type: 'item',
        //   url: '/apps/e-commerce/product-details/:id',
        //   breadcrumbs: false
        // },
        {
          id: 'product-list',
          title: <FormattedMessage id="product-list" />,
          type: 'item',
          url: '/apps/e-commerce/product-list',
          breadcrumbs: false
        }
        // {
        //   id: 'add-new-product',
        //   title: <FormattedMessage id="Add New Product" />,
        //   type: 'item',
        //   url: '/apps/e-commerce/add-new-product'
        // }
      ]
    },
    {
      id: 'Orders',
      title: <FormattedMessage id="Orders" />,
      type: 'collapse',
      icon: icons.orders,
      children: [
        {
          id: 'Order List',
          title: <FormattedMessage id="Order List" />,
          type: 'item',
          url: '/apps/orders/order-list',
          breadcrumbs: false
        }
      ]
    }
  ]
};

export default applications;
