import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { Bounce, toast } from 'react-toastify';
// import swal from 'sweetalert';

// ==============================|| SLICE - PRODUCTS ||============================== //

const initialState = {
  error: null,
  loadingForVendorData: false,
  loadingForDiscountedItems: false,
  postReqForSupplier: false,
  vendors: [],
  vendor: null,
  relatedVendors: [],
  reviews: [],
  addresses: [],
  discountedItems: []
};

const slice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
      // console.log(action.payload, 'error');
    },

    getVendorsPending(state) {
      state.loadingForVendorData = true;
    },
    // GET VENDORS
    getVendorsSuccess(state, action) {
      state.vendors = action.payload;
      state.loadingForVendorData = false;
    },

    //SEARCH VENDOR
    searchVendorSuccess(state, action) {
      // console.log(state);
      // console.log(action.payload, 'kkl');
      if (action.payload.targetVal === '') {
        state.vendors = action.payload.vendorData;
      } else {
        const res = action.payload.vendorData.filter((vendor) =>
          vendor.fullName.toLowerCase().includes(action.payload.targetVal.toLowerCase())
        );
        state.vendors = res;
      }
    },

    getDiscountedItemsPending(state) {
      state.loadingForDiscountedItems = true;
    },

    //DISCOUNTED ITEMS
    getDiscountedItemsSuccess(state, action) {
      state.discountedItems = action.payload;
      state.loadingForDiscountedItems = false;
    },

    assignDiscountPending(state) {
      state.postReqForSupplier = true;
    },

    assignDiscountSuccess(state) {
      state.postReqForSupplier = false;
      // console.log(action.payload, 'actionpayload');
      // if (action.payload) {
      //   action.payload.values.supplier_name = '';
      //   action.payload.values.discount = '';
      //   action.payload.value = [];
      // }
    },

    // FILTER PRODUCTS
    filterVendorsSuccess(state, action) {
      state.vendors = action.payload;
    },

    // GET PRODUCT
    getVendorSuccess(state, action) {
      state.vendor = action.payload;
    },

    // GET RELATED PRODUCTS
    getRelatedVendorsSuccess(state, action) {
      state.relatedVendors = action.payload;
    },

    // GET PRODUCT REVIEWS
    getVendorReviewsSuccess(state, action) {
      state.reviews = action.payload;
    },

    // GET ADDRESSES
    getAddressesSuccess(state, action) {
      state.addresses = action.payload;
    },

    // ADD ADDRESS
    addAddressSuccess(state, action) {
      state.addresses = action.payload;
    },

    // EDIT ADDRESS
    editAddressSuccess(state, action) {
      state.addresses = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getVendors(role) {
  // console.log(role, 'dattta');
  dispatch(slice.actions.getVendorsPending());
  return async () => {
    try {
      const response = await axios.get('/users', {
        params: {
          role: role
        },
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
      });
      // console.log(response, 'vendorslist');
      dispatch(slice.actions.getVendorsSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//SEARCH VENDOR
export function searchVendor(value) {
  // console.log(value, 'valueVendor');
  return async () => {
    try {
      const response = await axios.get('/users', {
        params: {
          role: 'supplier'
        },
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
      });
      // console.log(response.data.users, 'vendordata');

      dispatch(slice.actions.searchVendorSuccess({ targetVal: value, vendorData: response.data.users }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//POST CREATE VENDOR
export function createVendor(data) {
  // console.log(data, '9999');
  return async () => {
    try {
      const response = await axios.post('/suppliers', data.allFormData, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
      });
      // console.log(response, 'responseVendor');
      if (response) {
        toast.success('Supplier added successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          transition: Bounce
        });
        data.dialOff();
        data.reset();
      }
      dispatch(getVendors('supplier'));
      // values.dialOff();
      // console.log('Hello world');
    } catch (error) {
      // console.log(error);
      toast.error('Error on adding Supplier', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce
      });
    }
  };
}

//APPROVE VENDOR
export function approveVendor(data) {
  // console.log(data, 'approveVendor800');
  return async () => {
    try {
      const response = await axios.put(
        '/approve',
        { approve: !data.vendorApproval },
        {
          params: {
            role: 'supplier',
            contact_id: data.vendorContactId
          }
        }
      );
      // console.log(response, 'resApproveVendor');
      if (response) {
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          transition: Bounce
        });
      }
      dispatch(getVendors('supplier'));
    } catch (error) {
      // console.log(error);
      toast.error('Error on approving Supplier', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce
      });
    }
  };
}

//DISCOUNTED ITEMS
export function getDiscountedItems(role) {
  dispatch(slice.actions.getDiscountedItemsPending());
  return async () => {
    try {
      const response = await axios.get('/items/user', {
        params: {
          role: role
        },
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
      });
      // console.log(response.data, 'discountedItems');
      dispatch(slice.actions.getDiscountedItemsSuccess(response.data.items));
    } catch (error) {
      // console.log(error);
    }
  };
}

//ASSIGN DISCOUNT
export function assignDiscount(data) {
  // console.log(data, '666');
  dispatch(slice.actions.assignDiscountPending());
  return async () => {
    try {
      const response = await axios.post('/supplier/add/discount', data, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
      });
      // console.log(response, 'resAssignDiscount');
      if (response) {
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          transition: Bounce
        });
        dispatch(slice.actions.assignDiscountSuccess());
      }
      dispatch(getDiscountedItems('supplier'));
    } catch (error) {
      // console.log(error);
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce
      });
      dispatch(slice.actions.assignDiscountSuccess());
    }
  };
}

// UPDATE DISCOUNT OR MARKUP
export function updateDiscountOrMarkup(props) {
  // console.log(props, 'propsForEdit');
  return async () => {
    try {
      const response = await axios.put(
        '/supplier/edit/discount',

        Object.keys(props).includes('discount') ? { discount: props.discount } : { markup: props.markup },
        {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') },
          params: {
            product: props.product,
            supplier: props.supplier
          }
        }
      );

      if (response) {
        dispatch(getDiscountedItems('supplier')).then(() => {
          // console.log(res, 'uggf');
          toast.success(response.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
            transition: Bounce
          });
        });
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// REMOVE DISCOUNT
export function deleteSupplierDiscount(props) {
  return async () => {
    try {
      const response = await axios.delete('/supplier/delete/discount', {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') },
        params: {
          supplier: props.supplierId,
          product: props.productId
        }
      });
      // console.log(response.data, 'dataSupplier');
      dispatch(getDiscountedItems('supplier'));
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce
      });
      // .then((response) => {
      //   console.log(response, 'responseSupplier');
      //   toast.success('Discounted Product Deleted Successfully', {
      //     position: 'top-right',
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: 'dark',
      //     transition: Bounce
      //   });
      // });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterProducts(filter) {
  return async () => {
    try {
      const response = await axios.post('/api/products/filter', { filter });
      dispatch(slice.actions.filterProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProduct(id) {
  return async () => {
    try {
      const response = await axios.post('/api/product/details', { id });
      dispatch(slice.actions.getProductSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedProducts(id) {
  return async () => {
    try {
      const response = await axios.post('/api/product/related', { id });
      dispatch(slice.actions.getRelatedProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductReviews() {
  return async () => {
    try {
      const response = await axios.get('/api/review/list');
      dispatch(slice.actions.getProductReviewsSuccess(response.data.productReviews));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddresses() {
  return async () => {
    try {
      const response = await axios.get('/api/address/list');
      dispatch(slice.actions.getAddressesSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/new', address);
      dispatch(slice.actions.addAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/edit', address);
      dispatch(slice.actions.editAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
