// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import swal from 'sweetalert';
import { Bounce, toast } from 'react-toastify';

// ==============================|| SLICE - PRODUCTS ||============================== //

const initialState = {
  isDisable: false,
  isLoading: false,
  error: null,
  products: [],
  product: null,
  singleProductDetail: {},
  relatedProducts: [],
  reviews: [],
  addresses: [],
  resAfterProductAdded: {},
  targetIndex: []
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      // console.log(action.payload, 'action.payload')
      state.products = action.payload;
      state.isDisable = false;
      state.isLoading = false;
    },
    getProductsDataPending(state) {
      state.isLoading = true;
    },

    //GET SINGLE PRODUCT PENDING
    getSingleProductPending() {
      // state.loading = true;
    },
    // GET SINGLE PRODUCT
    getSingleProductSuccess(state, action) {
      // console.log(action.payload, '1234567');
      state.singleProductDetail = action.payload;
      state.isDisable = true;
      // state.targetIndex = [];
      // state.loading = false;
    },
    // getSingleProductSuccessForVar(state) {
    //   state.isDisable = true;
    //   // state.targetIndex = [];
    //   // state.loading = false;
    // },

    //ADD NEW PRODUCT RESPONSE
    addNewProductSuccess(state, action) {
      state.resAfterProductAdded = action.payload.allData;
      state.isDisable = true;
      swal('Done', `${action.payload.message}`, 'success');
    },

    // Add Single Product Variant
    singleVariantSuccess(state, action) {
      // state.isDisableForVarientField = true
      // console.log(action.payload, 'action999');
      state.targetIndex = [...state.targetIndex, action.payload.index];
      // toast.success(action.payload.resMsg);
      toast.success('Variant added successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce
      });
      // dispatch(slice.actions.getSingleProductDetail(action.payload.productId));
    },

    //UPDATE VARIANT SUCCESS
    updateVariantSuccess(state, action) {
      // console.log(action.payload, 'updateVariantSuccess');
      // const reqIndex = targetIndex.indexOf(action.payload);
      // state.targetIndex = state.targetIndex.splice(reqIndex, 1);
      state.targetIndex = state.targetIndex.filter((elem) => elem !== action.payload);
      // state.targetIndex = state.targetIndex.filter((elem) => elem !== action.payload.reqIndx);
      // dispatch(slice.actions.getSingleProductDetail(action.payload.productId));
      toast.success('Variant updated successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce
      });
    },

    // FILTER PRODUCTS
    filterProductsSuccess(state, action) {
      state.products = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.product = action.payload;
    },

    // GET RELATED PRODUCTS
    getRelatedProductsSuccess(state, action) {
      state.relatedProducts = action.payload;
    },

    // GET PRODUCT REVIEWS
    getProductReviewsSuccess(state, action) {
      state.reviews = action.payload;
    },

    // GET ADDRESSES
    getAddressesSuccess(state, action) {
      state.addresses = action.payload;
    },

    // ADD ADDRESS
    addAddressSuccess(state, action) {
      state.addresses = action.payload;
    },

    // EDIT ADDRESS
    editAddressSuccess(state, action) {
      state.addresses = action.payload;
    },

    clearTargetIndex(state) {
      state.targetIndex = [];
      // state.isDisable = false;
    },
    //EDIT BTN
    editBtnHandler(state, action) {
      // console.log(action, 'acc');
      // state.toggleBtn = action.payload;
      state.isDisable = !action.payload;
    },

    //EDIT BTN FOR VARIANT
    editBtnForVariant(state, action) {
      // console.log(action.payload, 'actionForEditVar');
      state.targetIndex = [...state.targetIndex, action.payload];
      // dispatch(getSingleProductDetail(action.payload.productId));
    },

    loadingChangeHandler(state, action) {
      state.isLoading = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ALL PRODUCT
export function getProducts() {
  dispatch(slice.actions.getProductsDataPending());
  return async () => {
    try {
      const response = await axios.get(`/items`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
      });
      // console.log(response.data.items, 'res000');
      dispatch(slice.actions.getProductsSuccess(response.data.items));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// SINGLE PRODUCT DETAILS
export function getSingleProductDetail(productId) {
  // console.log(productId, 'productId');
  dispatch(slice.actions.getSingleProductPending());
  return async () => {
    try {
      const singleProductData = await axios.get(`/items`, {
        params: {
          product_id: productId
        },
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
      });
      // console.log(singleProductData.data.items[0], 'singleProductRes222');
      // console.log(singleProductData.data.items[0], 'singleProRes');
      dispatch(slice.actions.getSingleProductSuccess(singleProductData.data.items[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//ADD NEW PRODUCT
export function addNewProduct(finaldata) {
  return async () => {
    try {
      const response = await axios.post('/items', finaldata, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
      });
      // console.log(response.data, 'responseAddProduct');
      if (response.data.success) {
        dispatch(slice.actions.addNewProductSuccess({ allData: response.data, message: response.data.message }));
      } else {
        toast.error('Error creating product', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce
        });
      }
      // console.log(response.data.message, 'responseAddProduct');
    } catch (error) {
      // console.log(error, 'prod-error');
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce
      });
      dispatch(slice.actions.hasError(error));
    }
  };
}

//UPDATE PRODUCT
export function updateProduct(dataForUpdate) {
  // console.log(dataForUpdate, 'dataForUpdate');
  dispatch(slice.actions.loadingChangeHandler(true));
  // dispatch(slice.actions.updateProductPending());

  return async () => {
    try {
      const response = await axios.put('/items', dataForUpdate.formData, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') },
        params: {
          product_id: dataForUpdate.product_id
        }
      });
      // console.log(response, 'updateResponse');

      dispatch(slice.actions.loadingChangeHandler(false));
      // dispatch(slice.actions.updateProductSuccess());
      dispatch(editBtnChange(false));
      swal('Done', response.data.message, 'success');
    } catch (error) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce
      });
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Add Single Product Variant
export function addSingleProductVariant(data) {
  // console.log(data, 'dataInSingleProduct');
  const singleVariantData = {};
  singleVariantData.variant_name = data.variant_name;
  singleVariantData.variant_price = data.variant_price;
  // console.log(singleVariantData, 'singleVariantData999');

  return async () => {
    try {
      const response = await axios.post(`/items/variants`, singleVariantData, {
        params: {
          product_id: data.productId
        }
      });
      // console.log(response.data, 'resForVariant');
      dispatch(slice.actions.singleVariantSuccess({ index: data.index, resMsg: response.data.message }));
      // dispatch(slice.actions.singleVariantSuccess(data.index));
      // dispatch(getSingleProductDetail(data.productId));
      // dispatch(slice.actions.clearTargetIndex());
    } catch (error) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce
      });
      dispatch(slice.actions.hasError(error));
    }
  };
}

//Add Single Product Variant For Edit/Update page
export function addSingleProductVariantForUpadate(data) {
  // console.log(data, 'dlk');
  const singleVariantData = {};
  singleVariantData.variant_name = data.variant_name;
  singleVariantData.variant_price = data.variant_price;
  // console.log(singleVariantData, 'singleVariantData999');

  return async () => {
    try {
      const response = await axios.post(`/items/variants`, singleVariantData, {
        params: {
          product_id: data.productId
        }
      });
      // console.log(response, '888');
      console.log(response.data, 'resForVariant');
      dispatch(getSingleProductDetail(data.productId));
      toast.success('Variant added successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce
      });
    } catch (error) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce
      });
      dispatch(slice.actions.hasError(error));
    }
  };
}

//VARIANT UPDATE
export function updateVariant(newVariantData) {
  // console.log(newVariantData, 'newVariantData');
  const updateVariantData = {};
  updateVariantData.variant_name = newVariantData.variantName;
  updateVariantData.variant_price = newVariantData.variantPrice;

  return async () => {
    try {
      const response = await axios.put(`/items/variants`, updateVariantData, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') },
        params: {
          product_id: newVariantData.productId,
          item_id: newVariantData.itemId
        }
      });
      console.log(response.data, 'res567');
      dispatch(slice.actions.updateVariantSuccess(newVariantData.reqIndx));
      // dispatch(slice.actions.updateVariantSuccess({ reqIndx: newVariantData.reqIndx, productId: newVariantData.productId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//DELETE SINGLE VARIANT
export function deleteSingleVariant(propsData) {
  // console.log(propsData, 'propsData');
  return async () => {
    try {
      const response = axios.delete(`/items/variants`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') },
        params: {
          product_id: propsData.productId,
          item_id: propsData.itemId
        }
      });
      console.log(response, 'deleteRes');
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//CHANGE STATUS
export function changeProductStatus(data) {
  // console.log(data, 'data');
  return async () => {
    try {
      const response = await axios.put(
        '/items',
        { status: data.productStatus },
        {
          params: {
            product_id: data.productId
          },
          headers: { Authorization: 'Bearer ' + localStorage.getItem('serviceToken') }
        }
      );
      // console.log(response.data, 'changeProductStatus');
      if (response) {
        dispatch(getProducts())
          .then(() => {
            // console.log(res);
            toast.success('Product status has been changed', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
              transition: Bounce
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce
      });
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterProducts(filter) {
  return async () => {
    try {
      const response = await axios.post('/api/products/filter', { filter });
      dispatch(slice.actions.filterProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProduct(id) {
  return async () => {
    try {
      const response = await axios.post('/api/product/details', { id });
      dispatch(slice.actions.getProductSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedProducts(id) {
  return async () => {
    try {
      const response = await axios.post('/api/product/related', { id });
      dispatch(slice.actions.getRelatedProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductReviews() {
  return async () => {
    try {
      const response = await axios.get('/api/review/list');
      dispatch(slice.actions.getProductReviewsSuccess(response.data.productReviews));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddresses() {
  return async () => {
    try {
      const response = await axios.get('/api/address/list');
      dispatch(slice.actions.getAddressesSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/new', address);
      dispatch(slice.actions.addAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/edit', address);
      dispatch(slice.actions.editAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export const clearTargetIndex = slice.actions.clearTargetIndex;
export const editBtnChange = slice.actions.editBtnHandler;
export const editBtnForVariant = slice.actions.editBtnForVariant;
